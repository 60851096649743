import React from 'react'
import "../../styles/TermsAndCondition.css"

const TermsAndCondition = () => {
  return (
    <>
    <div className='tcbox'>
        <div className='container'>
        <h2 style={{ display: "flex", justifyContent: "center", fontWeight: 600, marginBottom: 20 }}>Terms And Condition </h2>
        <h4 className='subheading'>Dhankosh, its Subsidiaries, affiliates and Group Companies (hereinafter collectively referred to as “Dhankosh” or “We” or “Us” or “Our”) welcomes you at dhankosh.com for doing your car, commercial vehicle, tractor or any other vehicle search, your search and access to certain financial products, including but not restricted to, loans, insurance & other financial products as offered by third parties, posting advertisements, etc.</h4>
        <p>THESE WEBSITE TERMS OF USE ("Terms of Use") IS AN ELECTRONIC RECORD IN THE FORM OF AN ELECTRONIC CONTRACT FORMED UNDER INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER AND THE AMENDED PROVISIONS PERTAINING TO ELECTRONIC DOCUMENTS / RECORDS IN VARIOUS STATUTES AS AMENDED BY THE INFORMATION TECHNOLOGY ACT, 2000. THESE TERMS OF USE DOES NOT REQUIRE ANY PHYSICAL, ELECTRONIC OR DIGITAL SIGNATURE.</p>
        <p>This Terms of Use is published in accordance with the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021, read with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 (as amended from time to time) that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of Website (defined below). The domain name <a href="https://dhankosh.com/">dhankosh.com</a> is owned by <b>Rapidlend Technologies Private Limited</b>, a company incorporated under the Companies Act, 1956.</p>
        <p>These Terms of Use of the website available at dhankosh.com and mobile site and the guest users or registered users of the Website (hereinafter referred to as "You" or "Your" or "Yourself" or "User") describe the terms on which Dhankosh offers you access to the Website for availing services and buying products, as are incidental and ancillary thereto ("Services", and more particularly defined hereinafter).</p>
        <p><b>1. Acknowledgment:</b></p>
        <p>Entire Agreement: These Terms of Use together with below listed documents/policies (without limitation) available either at Website or entered separately by Dhankosh with You, as applicable, and all other notices, rules, guidelines with respect to Your use of Website, constitutes the entire agreement (“Agreement”) between Dhankosh and You.</p>
        <p>&#8226; Privacy Policy</p>
        <p>&#8226; Additional Terms and Conditions</p>
        <p>&#8226; Terms of Sale</p>
        <p>&#8226; Consent declaration</p>
        <p>These additional documents/policies, wherever applicable, including terms imposed by mobile application stores like Apple’s iTunes, Android’s etc., are deemed to be incorporated under these Terms of Use by way of reference.</p>
        <p>You acknowledge and agree that Your usage (defined below) of the Website is strictly regulated and governed by the terms and conditions of this Agreement.</p>
        <p>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING OR REGISTERING ON THE WEBSITE OR, ACCESSING ANY MATERIAL, INFORMATION OR SERVICES, POSTING ANY ADVERTISEMENT/INFORMATION AT OR THROUGH THE WEBSITE. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, PLEASE DO NOT USE THE WEBSITE.</p>
        <p>Dhankosh may use third-party service providers to assist in improving our Services and Website and to monitor our Users’ interests and activities. You hereby authorize Dhankosh and/or third party or any other service providers engaged by Dhankosh to collect, use, store, analyze, reproduce, publish, and adapt (either on its own or through third party service providers or service providers belonging to its affiliate/group), the information in relation to Your use of the Website for the purpose of data analysis and for improving Your experience on the Website. You acknowledge that this is solely undertaken by Dhankosh to improve Your experience in relation to the use of the Website, and the provision of such Services shall be subject to such additional terms and conditions of Dhankosh and/or third-party service providers.</p>
        <p>Dhankosh shall not be responsible for any service availed by You from such third parties or any payment made by You to such third parties in connection with the services. Any concern or claims in relation to such services should be directed by You to such third parties. If you click on the links to third-party websites, you leave the Website. We are not responsible for the content of these third-party websites or for the security of Your personal information when You use the third-party websites.</p>
        <p><b>2. Eligibility:</b></p>
        <p>Service available through or use of the Website is available only to persons of majority (18 years of age or more) and who are legally competent to form a contract under the Indian Contract Act, 1872 (as amended from time to time), or any other act or enactments to which the person is subject.</p>
        <p>Persons who are incompetent to make a legal and valid contract, for any reason whatsoever (minor, plunatic, insane, undischarged insolvent, or otherwise) are not eligible to use Our Website and avail Our Services. BY ACCESSING OUR WEBSITE OR AVAILING ANY OF OUR SERVICES, YOU REPRESENT TO DHANKOSH THAT YOU ARE OF MAJORITY AGE AND LEGALLY COMPETENT TO FORM A VALID CONTRACT UNDER THE TERMS OF USE.</p>
        <p>Further to this, Dhankosh reserves its right, without assigning any reason, to restrict or limit Your access to the Website and can further terminate Your access to the Website and deny the Services or any other product available through or at Our Website. This right of suspension/termination of Services of Dhankosh is in addition to any other remedy available to Dhankosh, for access & usage of the Website or availing any of Our Services through the Website, which is in contravention of any of the terms and conditions of these Terms of Use or this Agreement or any other applicable law.</p>
        <p><b>3. Important:</b></p>
        <p>This Website is an online marketplace and an Intermediary in terms of the Information Technology Act, 2000 (as amended from time to time), which provides a platform to users to avail the Services. Any information provided either through or at the Website is only for information purposes and such information does not substitute any specific advice whether investment, legal, taxation or otherwise and are not intended to provide You with any nature of certification, guarantee or warranty. Dhankosh is not responsible and cannot be held liable for any transaction between the Website’s User.</p>
        <p>By accessing, browsing, and using this Website or availing Services, you agree and acknowledge that you understand this limited and restricted use and accessing, browsing, or using this Website or availing any Services is out of your own free will, discretion, and responsibility.</p>
        <p>Dhankosh reserves its right to modify, suspend, cancel, or discontinue any or all sections, or Services at any time without any notice. Dhankosh reserves the right to make modifications and alterations in the information contained on the Website without notice. You agree that Dhankosh shall not be liable to You or to any third party for any modification, suspension, or discontinuance of the Website.</p>
        <p>You expressly agree that any information provided on the Website shall be used by you at your risk. You are advised to hereby make proper inquiries and use the information provided on the Website.</p>
        <p>From time to time, Dhankosh may supplement its Terms of Use with additional terms pertaining to specific content. These terms are collectively referred to as “Additional Terms.” Such Additional Terms are hereby incorporated by reference into these Terms of Use.</p>
        <p><b>4. Services:</b></p>
        <p>Dhankosh provides/facilitates a platform to the Users of the Website and acts as an Intermediary. All information and material available on the Website is only to assist the User of the Website who are interested in the Services provided by Dhankosh and who want to explore more options before concluding their transactions. Among other services and information, Dhankosh may provide any or all the below-listed services to the Users:</p>
        <p>&#8226; The Website may provide information on new cars, new commercial vehicles, new tractors, or upcoming cars, commercial vehicles, tractors including without limitation price, specifications, dealer locations, etc.</p>
        <p>&#8226; Dhankosh may provide services to research cars, prices, technical specifications, other car attributes, and warranty and roadside assistance.</p>
        <p>&#8226; The services can include listing of your used cars, online or browsing through the list of used cars listed by Users of Dhankosh. Services can further include the facility to advertise/exhibit/make available for sale various products including without limitation auto products, accessories, services to other Users of the Website. Dhankosh makes no claims that any such information/data as available is accurate or applicability of the information to any specific situation.</p>
        <p>&#8226; The services can include listing of your used commercial vehicles, online or browsing through the list of commercial vehicles listed by Users of Dhankosh. Services can further include the facility to advertise/exhibit/make available for sale various products including without limitation auto products, accessories, services to other Users of the Website. Dhankosh makes no claims that any such information/data as available is accurate or applicability of the information to any specific situation.</p>
        <p>&#8226; The services can include listing of your used tractors, online or browsing through the list of tractors listed by Users of Dhankosh. Services can further include the facility to advertise/exhibit/make available for sale various products including without limitation auto products, accessories, services to other Users of the Website. Dhankosh makes no claims that any such information/data as available is accurate or applicability of the information to any specific situation.</p>
        <p>&#8226; Dhankosh may also provide the user the ability to send SMS to the user's top callers or send an email to the user's friends letting them know about the service.</p>
        <p>&#8226; The Website may provide certain Features, Content ("Features" and “Content” are defined below) that enable sending/receiving invites/solicitation messages/reminders to your contacts whose details, as required under the specific Features, are submitted, and specifically permitted by you to be so used.</p>
        <p>&#8226; The Website may provide the facility to the Users for their engagement in buying and selling various products under the Terms of Use.</p>
        <p>&#8226; The Website may also provide information on additional products of third parties, e.g., banks, financial institutions, insurance companies, dealers, etc., pertaining to loans, insurance, and other services ancillary and incidental thereto.</p>
        <p>All the above-listed services are provided at the sole discretion of Dhankosh, and You acknowledge and agree that Dhankosh can add or provide additional services not listed above, or can remove/suspend/cancel any or all services/products listed above without any notice or liability.</p>
        <p><b>5. Features And Content:
        </b></p>
        <p>“Contents" or "Content" shall mean any and all information, data, text, software, music, sound, photographs, graphics, video, messages, materials, news, notices, articles, contracts, forms, documents or other materials and information which may be viewed or downloaded on or through this Website. Contents shall also include any e-mail, messages, e-cards, or any other information furnished by a user to be displayed on the Website by Dhankosh.</p>
        <p>"Feature" or "Features": A "Feature" may include any interactive, value addition service or other additional feature including without limitation comparison, 360-degree view, etc., which are introduced or available on the Website.</p>
        <p>Dhankosh may allow the User of the Website to upload or post certain content, data or information, message, files, photographs, pictures, articles, feedback, or any other material or combination thereof (collectively referred to as "User Content") accessible to others or transmit communications to others. Some of these tools may be operated by third parties, and Your use of them is subject to both the terms of this Agreement and the policies of their third-party providers. This Feature is available to You as a matter of convenience only and to express Your views on products/services available through or on the Website.</p>
        <p>Being the originator of the User Content, You are solely responsible for the User Content that You upload, post, publish, transmit, or otherwise make available on the Website. You represent that you have obtained all relevant consents and approvals in order to post any User Content. You further represent that all such User Content will be in accordance with applicable law. You acknowledge and agree that the User Content posted or transmitted through our Website represents the views of the author, and not of Dhankosh. You also acknowledge that your use of or reliance on such content is at your own risk.</p>
        <p>You acknowledge that Dhankosh does not endorse any User Content on the Website and is not responsible or liable for any User Content. In case the User Content violates/infringes any patent, trademark, trade secret, copyright, or any other proprietary or privacy rights of any third party or is in contravention of any applicable law, then Dhankosh at its sole discretion may remove or disable the access to the User Content or any part thereof, without any notice to User.</p>
        <p><b>Assignment of User Content:</b> You hereby grant Dhankosh a perpetual, non-revocable, worldwide, royalty-free, and sub-licensable right and license to use, copy, distribute, display, publish, transmit, make available, reproduce, modify, adapt the User Content, and create derivative works of the User Content. You represent and warrant that You own or otherwise control all of the rights to the User Content that You post or that You otherwise provide on or through the Website; and that, as at the date that the User Content is posted or submitted on the Website: (i) the User Content is accurate; (ii) use of the User Content You supply does not breach these Terms of Use; and (iii) that such User Content is lawful. You agree that the collection, storage, sharing, or disclosure of Your Information that You provide to Us shall be governed by the Privacy Policy of Dhankosh, incorporated herein by way of reference.</p>
        <p><b>6. User’s Obligation:</b></p>
        <p>Subject to compliance with all applicable laws, rules, and terms and conditions of this Agreement, Dhankosh grants the User a limited, non-exclusive, non-sub-licensable, non-transferable, revocable, and limited permission to access and use the Website and avail of the Services provided through or at the Website.</p>
        <p>You acknowledge, agree, and undertake that Your use of the Website shall be strictly governed by this Agreement (including the Terms of Use) and the following binding principles:</p>
        <p>You shall not use or access the Website or avail of the Services by any means other than through the interface provided by Dhankosh.</p>
        <p>When You use the Website and/or the Services, You specifically undertake not to host, display, upload, modify, publish, transmit, update, or share any information or Content or User Content that:</p>
        <p>&#8226; belongs to another person and to which the User does not have any right;</p>
        <p>&#8226; is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating, or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever;</p>
        <p>&#8226; harms minors in any way;</p>
        <p>&#8226; infringes any patent, trademark, copyright, or other proprietary rights;</p>
        <p>&#8226; violates any applicable laws or regulations for the time being in force within or outside India;</p>
        <p>&#8226; deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</p>
        <p>&#8226; impersonates another person or entity;</p>
        <p>&#8226; contains software viruses or any other computer code, files, or programs designed to interrupt, destroy or limit the functionality of any computer resource;</p>
        <p>&#8226; threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states or public order or causes incitement to the commission of any cognizable offense or prevents investigation of any offense or is insulting to any other nation;</p>
        <p>&#8226; is misleading in any way or shall not, directly, or indirectly, offer, attempt to offer, trade, or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation, or guideline for the time being in force.</p>
        <p>&#8226; engages in any activity that interferes with or disrupts access to the Website or the Services available through or at the Website and shall not attempt or involve in the transmission of “junk/phishing mail,” “chain emails/letters,” “spamming” or “unsolicited mass mailing.”</p>
        <p>&#8226; attempts to gain unauthorized access to any other user’s account, Website, any portion or Feature of the Website, any other systems or networks connected to the Website, or any computer resource/servers, of Dhankosh connected with the Website to provide the Services. You shall not access the Website, Features, or any of the Services offered on or through the Website, by hacking, password mining or any other illegitimate means or through automated means including robot, deep link, page scrape;</p>
        <p>&#8226; You shall not probe, scan, or test the vulnerability of the Website or any network connected to the Website, nor disrupt, interfere, breach the security or authentication measures on the Website or any network connected to the Website or cause any harm to the Website, system resources, servers of Dhankosh connected to or accessible through the Website. You may not reverse look-up, trace or seek to trace any information on any other user, of or visitor to, the Website, or any other customer of Dhankosh, including any other Account on the Website not owned by You, to its source, or exploit the Website or Service or information made available or offered by or through the Website, in any way whether or not the purpose is to reveal any information, including but not limited to personal identification information, other than Your own information, as provided for by the Website;</p>
        <p>&#8226; uses the Website or Content for any purpose that is unlawful or prohibited by the Agreement, or to solicit the performance of any illegal activity or other activity which infringes the rights of Dhankosh or other third parties;</p>
        <p>&#8226; falsifies or deletes any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded;</p>
        <p>&#8226; violates any code of conduct or other guidelines, terms of this Agreement which may be applicable for or to any particular Service;</p>
        <p>&#8226; makes any negative, denigrating, or defamatory statement(s) or comment(s) about Us or the brand name, trade name or domain name used by Us or otherwise engage in any conduct or activity that might spoil the image or reputation of Dhankosh or buyers/sellers on Website or otherwise.</p>
        <p><b>7. Audit:</b></p>
        <p>Dhankosh at all times reserves its right, but not an obligation, to audit the Content, User Content, Features available on Website, or any other material or information posted/uploaded by the User. If at any time, in the sole discretion of Dhankosh, Dhankosh determines that any Content/User Content or any other material or Feature of the Website is in contravention of any law for the time being in force or the spirit of the terms and conditions of this Agreement or violates the privacy of any person, then Dhankosh may either remove or edit or block such material without any notice.</p>
        <p>Notwithstanding the above-said right, You acknowledge and understand that all User Content, whether publicly posted or privately transmitted, are the sole responsibility of the person from which such User Content originated. This means that You, and not Dhankosh, are entirely responsible for all User Content that you have provided Dhankosh with, in order to upload, post, transmit or otherwise make available via the Website. You further acknowledge and agree that these User Contents do not necessarily reflect the views of Dhankosh nor does Dhankosh endorse any such User Content.</p>
        <p>Further to this, it is informed that Dhankosh may share the information pertaining to such an instance (with or without demand) with a Government agency for the purpose of verification of identity of User, or for prevention, detection, investigation, prosecution, and punishment. Therefore it is advised to You that You should not involve in any such activities or use which violate any applicable law, rule, regulation, code of conduct, guideline, or judicial/quasi-judicial pronouncement/order.</p>
        <p>Dhankosh further reserves the right to investigate and take appropriate legal action against anyone who, in Dhankosh’s sole discretion, is suspected of violating any applicable law including, without limitation, reporting You to appropriate law enforcement authorities. You agree to grant Dhankosh all necessary permissions, approvals, consents, and waivers for the conduct of any audits, as required under this clause.</p>
        <p><b>8. Buyers</b></p>
        <p>Any User who buys products after visiting Our Website (“Buyer”) does so solely at their own discretion, risk, and responsibility. Dhankosh does not make any recommendations, nor does it give any warranty (implied or express), guarantee, or otherwise with respect to the quality, functionality, or fitness for a particular purpose of the products.</p>
        <p>You acknowledge and agree that the details available on the Website are provided solely for informational purposes. You further acknowledge and agree that any information, including actual charges of any product, may vary from time to time, and therefore You alone, and not Dhankosh, shall be responsible for any such charges or any additional cost incurred by the Buyer, or any other information discrepancies.</p>
        <p>Dhankosh may, from time to time, launch certain offers, coupons, or deals (“Scheme”) to promote the Website and enhance User engagement. As a Buyer, you acknowledge and agree that you shall strictly abide by the terms and conditions of the Scheme, as applicable, which shall be in addition to this Agreement.</p>
        <p>It is clarified that any Scheme provided by Dhankosh, unless otherwise stated, shall not be clubbed or combined with any other scheme available on the Website. Further to this, Dhankosh may, depending on various circumstances, suspend, cancel, or vary the Scheme without any notice.</p>
        <p>When a Buyer purchases any product after visiting the Website, the Buyer alone shall be responsible for completing the necessary documentation as per applicable laws. Dhankosh shall not be responsible for any consequences, including without limitation, delays in delivery of the product, cancellation of the transaction, or incomplete or improper documentation.</p>
        <p>If the Buyer requires certain additional services in relation to a product, such as warranty, installation, or after-sales services, the Buyer shall contact the seller/dealer who sold the product. Dhankosh is not and shall not be obliged to provide any such additional services. Dhankosh merely provides a platform to facilitate the engagement between Buyers and Sellers, and thus, in no way provides or is responsible for extending such additional services.</p>
        <p>Dhankosh strongly advises you not to test the Website with false purchase requests, as it may put you at substantial personal legal risk. It is a crime to use a false name or other false personal information to make a purchase. Willfully entering erroneous or fictitious purchase requests may result in prosecution by Dhankosh and/or the Seller. Please be aware that even if you do not provide Dhankosh with your real name, your web browser transmits a unique address to us, which can be used by law enforcement officials to identify you.</p>
        <p><b>9. Links to Third-Party Websites and Third-Party Content:</b></p>
        <p>Our Website may contain hyperlinks to websites operated by parties other than Dhankosh, or third-party information such as product descriptions, catalogues, dealer details, news, videos, photographs, and any other data made available by third parties ("Third Party Content"). The provision of Third Party Content is for general information purposes only.</p>
        <p>Dhankosh makes reasonable efforts to ensure that the details provided on our Website are accurate; however, Dhankosh does not control such Third Party Content and, therefore, shall not be responsible for any loss or damages resulting from the use of Third Party Content.</p>
        <p>The inclusion of Third Party Content on Our Website does not imply the Company's certification or endorsement of such websites nor any association with their operators. Dhankosh, its subsidiaries, affiliates, associates, or its employees do not provide any judgment or warranty regarding the authenticity or correctness of the Third Party Content or such other services or sites to which links are provided. A link to another service or website is not an endorsement of any products or services on such a site or the Website. You shall be solely responsible for any or all consequences that arise out of your use of such Third Party Content or hyperlinks to other websites.</p>
        <p>On the Website, Google, as a third-party vendor, uses cookies to serve ads. Google's use of the DoubleClick DART Cookie enables it to serve ads to the Website’s users based on their visit to the Website and third-party websites. For more information on the DART Cookie, see: http://www.google.com/privacy ads.html. You may opt out of the use of the DART Cookie by Google by visiting the Google Ad and Content Network Privacy Policy at: http://www.google.com/privacy_ads.html.</p>
        <p>You may opt out of targeting advertising for all NAI member ad networks by visiting: http://www.networkadvertising.org/. By publishing an ad on the Platform, you acknowledge and agree that the content is public and accessible by any third party, and that it may appear in search engine results (such as Yahoo!, MSN, Google, Altavista, and other search engines) and in the cache of those search engines, in feeds, and on third-party websites pursuant to co-branding agreements. It is the sole responsibility of each of those search engines, third-party websites, or RSS web feed resources to update and/or remove content from their indexes and their cache. You agree and acknowledge that Dhankosh is not liable for the information published in search results or by any third-party website that carries Dhankosh postings.</p>
        <p><b>10. Fees & Payments:</b></p>
        <p>Access to the Website is free, and users (registered or guest) can browse the information/products and avail of the services free of charge. However, Dhankosh reserves the right to levy certain charges and fees in the future. You agree to pay any such fee as may be applicable to the services or products that you avail.</p>
        <p><b>Taxes:</b> You are responsible for paying all fees associated with the use of the Website, and you agree to bear any and all applicable taxes, charges, cesses, etc., levied thereon.</p>
        <p>You shall be responsible for all your fee and payment-related obligations, and Dhankosh reserves the right to suspend the services/account or terminate the membership if you do not fulfil your payment-related obligations in full. We also reserve the right to take legal action in case of non-payment of fees.</p>
        <p>You acknowledge and agree that you shall not use the details of a credit card/debit card/net-banking or any other payment instrument that is not lawfully owned by you.</p>
        <p>Dhankosh, from time to time, may contract with third-party payment processing service providers to facilitate payment of orders that you place on our Website, including banks, financial institutions, etc. You shall be responsible for abiding by all rules/regulations/guidelines as issued and applicable to online payments.</p>
        <p>When you make the payment through a third-party payment gateway, such a third party processes your payment to Dhankosh, provided you comply with all applicable rules and regulations. Dhankosh shall not be responsible for any failure/decline in making payment due to any reason (including, without limitation, exceeding limit, wrong details, etc.), as Dhankosh does not control such third parties.</p>
        <p><b>11. License, Website Access & Modification</b></p>
        <p><b>License and Website Access: </b>Subject to compliance with all applicable laws, rules, and terms and conditions of this Agreement and/or Additional Terms, Dhankosh grants the User a limited, non-exclusive, non-sub-licensable, non-transferable, revocable, and limited permission to access and use the Website and avail of the services provided through or at the Website, subject to the following conditions:</p>
        <p>&#8226; The license shall not include: (i) any resale or commercial use of this Website or its contents; or (ii) any derivative use of this Website or its contents.</p>
        <p>&#8226; You, your partners, your employees, your agents, or any other person associated with you personally or professionally, may not, with or without compensation, prepare any derivative works from, or sell, lease, license, email, reproduce, duplicate, copy, resell, republish, transmit, or distribute or otherwise exploit for any commercial purpose via any media, now known or unknown, any contents from this Website to any other entity or individual.</p>
        <p>&#8226; No contents or information on the Website may be reproduced in any form or incorporated into any internet website or any other information retrieval system, either electronic or mechanical. It is a condition of your use of this Website that you do not restrict or inhibit any other user from using this Website.</p>
        <p>&#8226; You shall not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of Dhankosh and/or our affiliates without the express written consent of Dhankosh. You may not use any meta tags or any other "hidden text" utilizing Dhankosh’s name or trademarks without the express written consent of Dhankosh.</p>
        <p>&#8226; Any software that is available on the Website is the property of Dhankosh or its vendors. You may not use, download, or install any software available on the Website unless otherwise expressly permitted by the Agreement or by the express written permission of Dhankosh.</p>
        <p>Any unauthorized use terminates the permission and the license granted by Dhankosh under this Agreement to you.</p>
        <p><b>Modification:</b>All information available on the Website is made available to you on a best-efforts basis; however, Dhankosh does not guarantee nor assume any responsibility for the correctness of such information/data. Dhankosh reserves the right to modify, suspend/cancel, or discontinue any or all sections, or service at any time without notice. If the information is found to be not up-to-date or incorrect, Dhankosh reserves the right to make modifications and alterations in the information contained on the Website without notice. You agree that Dhankosh shall not be liable to you or any third party for any modification, suspension, or discontinuance of the Website.</p>
        <p>You expressly agree that any information provided on the Website shall be used by you at your risk. You are advised to make proper inquiries and use the information provided on the Website.</p>
        <p>From time to time, the Website may supplement its terms of use with Additional Terms pertaining to specific content. Such Additional Terms are hereby incorporated by reference into these Terms of Use.</p>
        <p><b>12. Intellectual Property Rights:</b></p>
        <p>All text, graphics, audio, design, and other works on the Website are the copyrighted works of Dhankosh or its licensors. Content on the Website is for personal use only unless permitted otherwise. Any alteration of the material or use of the material contained on the Website for any commercial purpose is a violation of the copyright of the Company and/or its affiliates or group or associates or of its third-party information providers.</p>
        <p>The Website may also contain certain trademarks, logos, or service marks ("Marks"). These Marks are proprietary to Dhankosh, its vendors/sellers, or respective third parties. You are not permitted to use the Marks, in any manner, without first obtaining the written permission of Dhankosh or its vendor or third party that owns and controls the Marks.</p>
        <p>YOU ACKNOWLEDGE AND AGREE THAT THROUGH YOUR USE OF THE WEBSITE OR BY AVAILING ANY PRODUCT OR SERVICE THROUGH OR AT THE WEBSITE, NO RIGHT (EXPRESS OR IMPLIED) IS GRANTED TO YOU IN RESPECT OF SUCH CONTENT. DHANKOSH RESERVES THE RIGHT TO CHANGE OR MODIFY THE CONTENT FROM TIME TO TIME AT ITS SOLE DISCRETION.</p>
        <p>You further acknowledge and agree that you shall not copy, reproduce, republish, upload, post, transmit, or distribute the Content available on the Website in any way without obtaining prior permission from Dhankosh or its licensors. All responsibility and liability for any damages caused by downloading any data is disclaimed.</p>
        <p>It is clarified that any use of the Website, Content, Marks, or other intellectual property rights of Dhankosh, in contravention of the terms of this Agreement or any applicable law, shall constitute an infringement of such intellectual property rights of Dhankosh or their respective third parties, upon which Dhankosh or the third party may initiate appropriate legal proceedings against the User.</p>
        <p>Unless otherwise indicated or anything contained to the contrary, or any proprietary material owned by a third party and so expressly mentioned, Dhankosh owns all intellectual property rights to and into the Website, including, without limitation, any and all rights, titles, and interest in and to copyright, related rights, patents, utility models, designs, know-how, trade secrets and inventions (patent pending), goodwill, source code, meta tags, databases, text, content, graphics, icons, and hyperlinks.</p>
        <p>ALL RIGHTS NOT EXPRESSLY GRANTED HEREUNDER TO THE USER ARE RESERVED EXCLUSIVELY TO DHANKOSH.</p>
        <p><b>13. Disclaimer of Warranty and Liability:</b></p>
        <p>ALL CONTENT, USER CONTENT, THIRD-PARTY CONTENT, OR INFORMATION PERTAINING TO THE WEBSITE OR ANY PRODUCT / SERVICES ARE PROVIDED TO YOU ON AN “AS IS” BASIS AND WITHOUT WARRANTY OF ANY KIND, EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE.</p>
        <p>WHEN YOU ACCESS THE WEBSITE OR AVAIL ANY PRODUCT OR SERVICES AT OR THROUGH THE WEBSITE, YOU DO SO AT YOUR OWN RISK. YOU UNDERSTAND THAT THERE MAY BE DELAYS, OMISSIONS, INTERRUPTIONS, INACCURACIES, AND/OR OTHER PROBLEMS WITH THE INFORMATION, PRODUCTS, AND SERVICES PUBLISHED ON OR PROMOTED THROUGH THIS WEBSITE. WITHOUT LIMITING THE FOREGOING, DHANKOSH AND ITS AFFILIATES, AGENTS, AND LICENSORS MAKE NO WARRANTY THAT:</p>
        <p>&#8226; THE WEBSITE OR THE SERVICES WILL MEET YOUR REQUIREMENTS OR YOUR USE OF THE WEBSITE OR THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE;</p>
        <p>&#8226; THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE WEBSITE OR SERVICES WILL BE EFFECTIVE, ACCURATE, OR RELIABLE;</p>
        <p>&#8226; THE QUALITY OF THE WEBSITE OR SERVICES WILL MEET YOUR EXPECTATIONS; OR</p>
        <p>&#8226; ANY ERRORS OR DEFECTS IN THE WEBSITE OR SERVICES WILL BE CORRECTED. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM DHANKOSH OR THROUGH THE WEBSITE / CONTENT OR FROM USE OF THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS OF USE.</p>
        <p>THE WEBSITE WILL BE ERROR-FREE, OR CONTINUOUSLY AVAILABLE, OR THAT THIS WEBSITE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS AND OTHER CODES THAT CONTAIN CONTAMINATING OR DESTRUCTIVE PROPERTIES. RAPIDLEND TECHNOLOGIES PRIVATE LIMITED AND ITS AFFILIATES, AGENTS, AND LICENSORS WILL NOT BE LIABLE FOR ANY CLAIMS, ACTIONS, OR JUDGMENTS ARISING OUT OF OR RELATED TO ANY CONTENT POSTED TO THIS WEBSITE BY YOU OR ANY THIRD PARTY.</p>
        <p>TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO THE APPLICABLE LAW, DHANKOSH DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, RELATED TO THE WEBSITE, CONTENT, USER CONTENT, PRODUCT SERVICES, THIRD-PARTY CONTENT ARISING UNDER INTELLECTUAL PROPERTY RIGHTS, LIBEL, PRIVACY, PUBLICITY, OBSCENITY, OR OTHER LAWS. DHANKOSH ALSO DISCLAIMS ALL LIABILITY WITH RESPECT TO THE MISUSE, LOSS, MODIFICATION, OR UNAVAILABILITY OF ANY USER CONTENT AND/OR THIRD-PARTY CONTENT.
        </p>
        <p>ANY MATERIAL, CONTENTS, SOFTWARE, OR INFORMATION DOWNLOADED OR OTHERWISE OBTAINED FROM THE WEBSITE IS SUBJECT TO THIS AGREEMENT AND DONE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. DHANKOSH IS NOT LIABLE FOR THE AUTHENTICITY OF SUCH DATA/INFORMATION.</p>
        <p>WITHOUT LIMITING THE FOREGOING, UNDER NO CIRCUMSTANCES SHALL DHANKOSH BE HELD LIABLE FOR ANY DELAY OR FAILURE IN PERFORMANCE RESULTING DIRECTLY OR INDIRECTLY FROM ACTS OF NATURE, FORCES, OR CAUSES BEYOND ITS REASONABLE CONTROL, INCLUDING, WITHOUT LIMITATION, INTERNET FAILURES, COMPUTER EQUIPMENT FAILURES, TELECOMMUNICATION EQUIPMENT FAILURES, OTHER EQUIPMENT FAILURES, ELECTRICAL POWER FAILURES, STRIKES, LABOR DISPUTES, RIOTS, INSURRECTIONS, CIVIL DISTURBANCES, SHORTAGES OF LABOR OR MATERIALS, FIRES, FLOODS, STORMS, EXPLOSIONS, ACTS OF GOD, WAR, GOVERNMENTAL ACTIONS, ORDERS OF DOMESTIC OR FOREIGN COURTS OR TRIBUNALS, NON-PERFORMANCE OF THIRD PARTIES, OR LOSS OF OR FLUCTUATIONS IN HEAT, LIGHT, OR AIR CONDITIONING.</p>
        <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, DHANKOSH WILL NOT BE LIABLE FOR ANY LOSS THAT YOU MAY INCUR AS A CONSEQUENCE OF UNAUTHORIZED USE OF YOUR ACCOUNT OR ACCOUNT INFORMATION IN CONNECTION WITH THE WEBSITE OR ANY SERVICES, EITHER WITH OR WITHOUT YOUR KNOWLEDGE. YOU FURTHER ACKNOWLEDGE AND AGREE THAT IF YOU PURCHASE/DOWNLOAD THE WEBSITE FROM OTHER THIRD-PARTY SERVICE PROVIDERS (e.g., GOOGLE PLAY STORE, APPLE, ETC.), THEN NEITHER DHANKOSH NOR ANY THIRD-PARTY SERVICE PROVIDER SHALL BE HELD LIABLE FOR ERROR OR FAILURE ON THE PART OF THE WEBSITE TO FUNCTION PROPERLY. YOU SHALL FOLLOW THE RULES/GUIDELINES BEFORE DOWNLOADING/INSTALLING THE WEBSITE AS RECOMMENDED BY THIRD-PARTY SERVICE PROVIDERS.</p>
        <p><b>14. Indemnification and Limitation of Liability:</b></p>
        <p><b>Indemnity: </b> You agree to indemnify, defend, and hold harmless Dhankosh, its holding company, subsidiaries, affiliates, and its group companies, vendors, agents, and their respective directors, officers, employees, contractors, and agents (herein after individually and collectively referred to as "Indemnified Parties") from and against any and all losses, liabilities, claims, suits, proceedings, penalties, interests, damages, demands, costs, and expenses (including legal and other statutory fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by the Indemnified Parties that arise out of, result from, or in connection with (i) Your breach of the Agreement(s); or (ii) any claims made by any third party due to, or arising out of, or in connection with, Your use of the Website; or (iii) any claim that any Content / User Content / Third Party Content, information or materials provided by You caused damage to a third party; or (iv) Your violation of any rights of another, including any intellectual property rights.</p>
        <p>Dhankosh may notify You of any claims which You shall be liable to indemnify Dhankosh against. You will then be required to consult with Dhankosh regarding the course of action to be undertaken in defending such a claim. Further, You shall not compromise or settle any claim or admit any liability or wrongdoing on the part of Dhankosh without the express prior written consent of Dhankosh, which can be withheld, denied, or conditioned by Dhankosh in its sole discretion.</p>
        <p><b>Limitation of Liability:</b> Dhankosh assumes no liability whatsoever for any monetary or other damage suffered by You on account of:</p>
        <p>&#8226; The delay, failure, interruption, or corruption of any data or other information transmitted in connection with the use of the Website;</p>
        <p>&#8226; Any interruption or errors in the operation of the Website.</p>
        <p>Notwithstanding anything to the contrary in the Agreement(s), in no event shall Dhankosh, its subsidiaries or affiliates, its group companies, and their respective officers, directors, employees, partners, or suppliers be liable to You for any special, incidental, indirect, consequential, exemplary, or punitive damages whatsoever, including those resulting from loss of use, data, or profits, whether or not foreseeable or whether or not Dhankosh has been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortious action, or any other claim arising out of or in connection with Your use of or access to the Website, Services, Content, User Content, or Third-Party Content. Dhankosh’s entire and aggregate liability to You under and in relation to these Terms of Use or otherwise shall not exceed the greater of Indian Rupees One Hundred (INR 100) or the amount of fees, if any, paid by You to Dhankosh under the relevant order to which the cause of action for the liability relates.</p>
        <p><b>15. Platform for Communication:</b></p>
        <p>This Website is only a platform to facilitate interaction between the Users of the Website and to provide an opportunity to browse the products and Services and conclude the transactions.</p>
        <p>Therefore, Dhankosh is not and cannot be a party to or control in any manner any transaction between two Users of the Website. Consequently:</p>
        <p>&#8226; All commercial/contractual terms are offered by and agreed to between the Buyer and Seller alone.</p>
        <p>Dhankosh is not responsible for any non-performance or breach of any contract entered into between Users. Dhankosh cannot and does not guarantee that the concerned Users will perform any transaction concluded on the Website.</p>
        <p>You further agree and acknowledge that Dhankosh is only a facilitator and is not and cannot be a party to or control in any manner any advertisement, exhibition, making available, offer to sell, or transactions of sale or purchase of products/Services on the Website. Dhankosh is not the seller of the products. Accordingly, any contract for the sale/purchase of products/Services on the Website is a bipartite contract between Buyer and Seller.</p>
        <p>You shall independently agree upon the manner and terms and conditions of delivery, payment, insurance, etc. with the other Users that you transact with.</p>
        <p>You release and indemnify Dhankosh and/or any of its officers and representatives from any cost, damage, liability, or other consequence of any of the actions of the Users of the Site and specifically waive any claims that you may have in this behalf under any applicable law. Notwithstanding its reasonable efforts in that behalf, Dhankosh cannot control the information provided by other Users which is made available on the Website. You may find other User's information to be offensive, harmful, inaccurate, or deceptive. Please use caution, common sense, and practice safe trading when using the Website.</p>
        <p>Please note that there are also risks of dealing with foreign nationals, underage persons, or people acting under false pretenses. Dhankosh does not accept any responsibility for the accuracy or reliability of any information, data, opinions, advice, or statements made on the Website by other Users and does not accept any liability for any inaccuracy, error, or omission.</p>
        <p><b>16. Report Abuse & Your Feedback:</b></p>
        <p>Dhankosh advises its Users not to post or upload anything that abuses the Website or violates any terms of this Agreement. Dhankosh also prohibits the use of language that is racist, hateful, sexual, or obscene in nature in a public area. If You come across any such instance of abuse, violation, or any content that is objectionable, or if You believe that any material/information/post/feedback infringes any intellectual property rights of You or any other person, You shall inform the same to Dhankosh, and Dhankosh is committed to taking appropriate action, including validation of Your information and removing/modifying the content (if necessary) that is in breach of the Terms of Use or any other applicable law.</p>
        <p>Email Abuse and Threat Policy: Private communication, including email correspondence, is not regulated by Dhankosh. Dhankosh advises its Users not to use/send any such email. Any such reported instance will be investigated, and Dhankosh will take appropriate measures (including legal steps, if required) to stop such practices.</p>
        <p>Your Feedback: When You share any feedback or post any comment (including User Content) on the Website, it is deemed Non-Confidential. Dhankosh is at liberty to use any such feedback or User Content, and You represent that: (i) Your feedback does not contain confidential or proprietary information of You or third parties; (ii) Dhankosh is not under any obligation of confidentiality, express or implied, with respect to the feedback; (iii) Dhankosh may have something similar to the feedback already under consideration or in development; and (iv) You are not entitled to any compensation or reimbursement of any kind from Dhankosh for the feedback under any circumstances.</p>
        <p><b>17. Arbitration:
        </b></p>
        <p>You and Dhankosh each agree that any and all disputes or claims that have arisen, or may arise, between You and Dhankosh relating in any way to, or arising out of this or previous versions of the Agreement, or any other documents incorporated herein by way of reference, or Your use of or access to Dhankosh’s Services, or any products or Services sold, offered, or purchased through Dhankosh’s Services shall be referred to the sole arbitrator who shall be an independent and neutral third party and shall be appointed by Dhankosh.</p>
        <p>The place of arbitration shall be Delhi, India. The arbitration proceedings shall be in the English Language and shall be governed by the Arbitration & Conciliation Act, 1996 (as amended from time to time).</p>
        <p>A party who intends to seek arbitration must first send to the other, by certified mail, a completed form Notice of Dispute ("Notice"). The Notice to Dhankosh must be sent to the address identified under clause 20 (Miscellaneous)</p>
        <p><b>18. Miscellaneous:</b></p>
        <p><b>Notice: </b>Except as explicitly stated otherwise, any notices shall be given by postal mail/email</p>

        <p>On behalf of Dhankosh to:</p>
        <p>Rapidlend Technologies Private Limited</p>
        <p>Attn: Legal Function</p>
        <p>Registered Address: 554, Mandakini Enclave, Alaknanda, New Delhi-110019</p>
        <p>Email: care@dhankosh.com</p>
        <p>All notices with respect to these Terms of Use from Dhankosh will be served to You:</p>
        <p>By email (as provided by You at the time of Registration or communicating with Dhankosh) or by general notification on the Website.</p>
        <p><b>Assignment:</b>
        You cannot assign or otherwise transfer the Agreements, or any rights granted hereunder, or any obligations, to any third party, and any such assignment or transfer or purported assignment or transfer shall be void ab initio. Dhankosh’s rights and/or obligations under the Agreement are freely assignable or otherwise transferable by Dhankosh to any third parties without the requirement of seeking Your prior consent. Dhankosh shall have the right to transfer Your Account and Account Information to a third party.</p>
        <p><b>Severability: </b>If any clause of this Agreement shall be deemed invalid, void, or for any reason unenforceable, such clause shall be deemed severable and shall not affect the validity and enforceability of the remaining clauses of the Agreement.</p>
        <p><b>Waiver: </b>Any failure or delay by a party to enforce or exercise any provision of the Agreement, or any related right, shall not constitute a waiver by such party of that provision or right. The exercise of one or more of a party's rights hereunder shall not be a waiver of, or preclude the exercise of, any rights or remedies available to such party under these Terms of Use or in law or at equity. Any waiver by a party shall only be made in writing and executed by a duly authorized officer of such party.</p>
        <p><b>Independent Contractor: </b>You and Dhankosh are independent contractors, and nothing in these Terms of Use will be construed to create a partnership, joint venture, association of persons, agency (disclosed or undisclosed), franchise, sales representative, or employment relationship between You and Dhankosh.</p>
        <p><b>Contacting You: </b> By accessing Our Website and/or availing of any service or product at or through Our Website, You give Your consent to Us and both our associate partners (including Dealers, financial institutions, etc.) to communicate with You through phone calls/SMS/email communication, etc., regardless of whether Your number is registered in the National Do Not Call Registry (NDNC) or www.nccptrai.gov.in.
        </p>
        <p><b>Grievance Officer: </b> Users can file their complaints regarding violations of the Information Technology Act, 2000, and rules prescribed thereunder, or any other matter pertaining to the Website, with the Grievance Officer of Dhankosh on the contact information specified herein below:</p>
        <p><b>Name: </b>Mr. Rajat Joshi</p>
        <p><b>E-mail: </b>care@dhankosh.com</p>
        <p><b>19. Governing Law and Jurisdiction:</b></p>
        <p>This Agreement or the documents incorporated herein by reference shall be governed and construed in accordance with the laws of India. Subject to Clause 19 (Arbitration) above, all disputes arising under this Agreement between You and Dhankosh shall be subject to the exclusive jurisdiction of courts at Delhi, India.
        </p>
        </div>
    </div>
    </>
  )
}

export default TermsAndCondition