import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Headerlogo from '../../assets/images/dhankoshlogo.png';
// import DropdownImage from '../../assets/images/dropdown.png';
import '../../styles/Header.css';

const Headers = () => {
    const [navbar, setNavbar] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const location = useLocation();
    const dropdownRef = useRef(null);

    const handleMenu = () => {
        setNavbar(!navbar);
    };

    const closeNavbar = () => {
        setNavbar(false);
    };

    // const toggleDropdown = (e) => {
    //     e.preventDefault();
    //     setDropdownOpen(!dropdownOpen);
    // };

    const handleHomeClick = () => {
        if (navbar) {
            closeNavbar();
        }
        scrollToTop();
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header className="header">
            <div className="container">
                <div className="headericon">
                    <div className="headerfix">
                        <div className="menu">
                            <div className="headerimgmobile">
                                <Link to="/" onClick={scrollToTop}>
                                    <img src={Headerlogo} alt="logo" width={120} height={42} />
                                </Link>
                            </div>
                            <div className="headerimg">
                                <Link to="/" onClick={scrollToTop}>
                                    <img src={Headerlogo} alt="logo" width={182} height={56} />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <nav className={navbar ? 'navbar navbaropen' : 'navbar'}>
                        <ul className="nav-list">
                            <li className="crossbtn" onClick={closeNavbar}>X</li>
                            <li className="home-menu">
                                <div className="home-dropdown-container" ref={dropdownRef}>
                                    <Link 
                                        to="/" 
                                        onClick={() => { handleHomeClick(); scrollToTop(); }}
                                        className={location.pathname === '/' ? 'active' : ''}
                                    >
                                        HOME
                                        {/* <img 
                                            src={DropdownImage} 
                                            alt="dropdown" 
                                            className="dropdown-image" 
                                            onClick={toggleDropdown} 
                                        /> */}
                                    </Link>
                                    {dropdownOpen && (
                                        <div className="dropdown-menu">
                                            <Link to="#" onClick={() => { closeNavbar(); scrollToTop(); }}>Agent</Link>
                                            <Link to="#" onClick={() => { closeNavbar(); scrollToTop(); }}>Dealer</Link>
                                        </div>
                                    )}
                                </div>
                            </li>
                            <li>
                                <Link 
                                    to="/about-us" 
                                    onClick={() => { closeNavbar(); scrollToTop(); }} 
                                    className={location.pathname === '/about-us' ? 'active' : ''}
                                >
                                    ABOUT US
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to="/partner-with-us" 
                                    onClick={() => { closeNavbar(); scrollToTop(); }} 
                                    className={location.pathname === '/partner-with-us' ? 'active' : ''}
                                >
                                    PARTNER WITH US
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to="/contact-us" 
                                    onClick={() => { closeNavbar(); scrollToTop(); }} 
                                    className={location.pathname === '/contact-us' ? 'active' : ''}
                                >
                                    CONTACT US
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <aside className={navbar ? 'navbackdrop navbackdropopen' : 'navbackdrop'} onClick={handleMenu}></aside>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                            <span className="headerspan" onClick={handleMenu}>
                                <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24">
                                    <rect y="11" width="24" height="2" rx="1" />
                                    <rect y="4" width="24" height="2" rx="1" />
                                    <rect y="18" width="24" height="2" rx="1" />
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Headers;
